<template>
    <div class="sub_layout_container">
        <div class="title_container clearfix">
            <div class="title_left">
                <div class="title_display">
                    <img v-if="back" src="../assets/back.png" alt="" class="back" @click="onBack">
                    <span class="title">{{title}}</span>
                </div>
            </div>
            <div class="title_right">
                <div class="title_display">
                    <img v-if="refresh" src="../assets/refresh.png" alt="" class="refresh" @click="onRefresh">
                </div>
            </div>

        </div>
        <div class="content-container">
            <router-view ref="child"></router-view>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'SubLayout',
        data() {
            return {
                title: '',
                back: false,
                refresh: false,
            }
        },
        watch: {
            $route() {
                this.getSubTitle()
            }
        },
        created() {
            this.getSubTitle()
        },

        methods: {
            getSubTitle() {
                let matched = this.$route.matched.filter(item => item.meta && item.meta.title);

                if (matched.length >= 3) {
                    this.title = matched[2].meta.title;
                    this.back = matched[2].meta.back;
                    this.refresh = matched[2].meta.refresh;
                }
                // console.log(this.refresh);
            },
            onBack() {
                this.$router.go(-1);
            },
            onRefresh() {
                this.$message.success("正在刷新");
                if (this.$refs.child.onRefresh) {
                    this.$refs.child.onRefresh();
                } else {
                    console.log("子组件未实现 onRefresh 方法");
                }

            }
        }
    }
</script>

<style scoped lang="scss">
    .sub_layout_container {
        min-height: calc(100vh - 135px);
        background: white;
        margin-top: 20px;

        .title_container {
            box-sizing: border-box;
            padding-left: 30px;
            height: 60px;
            border-bottom: 1px solid #EBEEF5;

            .title_left {
                float: left;
                height: 100%;
                display: inline-block;
            }

            .title_right {
                float: right;
                height: 100%;
                display: inline-block;
                margin-right: 30px;
            }

            .title_display {
                display: flex;
                height: 100%;
                align-items: center;
            }

            .back {
                width: 20px;
                height: 20px;
                display: inline-block;
                margin-bottom: 1px;
                margin-right: 10px;
            }

            .refresh {
                float: right;
                width: 30px;
                height: 30px;
                display: inline-block;
            }

            .refresh:hover {
                cursor: pointer
            }

            .title {
                font-weight: 600;
            }
        }

        // 内容容器
        .content-container {
            padding: 16px;
        }
    }

</style>
